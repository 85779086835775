<template>
  <SiteMain>
    <PageLayout
        :goBack="previousPage"
        :pageTitle="page.title ? `${$t('Phase')} ${page.title}` : ''"
        :pageVideo="page.video"
        :pageIntroTwo="page.introduction"
        :imgZoom="page.phase_image"
        :pageContent="page.body">
      <GridSection
          blockHeading
          colPadding
          flexDisplay
          :headingSmall="page.videos_header"
          class="content">
        <Card
            class="col-md-6 col-lg-4 col-p"
            v-for="(post, i) in posts"
            :key="i"
            :title="post.title"
            :image="post.image"
            :link="post.slug"
            :duration="post.view_time"
            :phase="post.phase_title"/>
      </GridSection>
    </PageLayout>

    <Faq :title="faqTitle" :filter-enabled="false" :filter-default="page.faq_default" :padding-enabled="true">
      <div class="faq-cta--btn">
        <p class="text-small">{{ $t("Didn't find what you are looking for?") }}</p>
        <router-link :to="`/faq?faqDefault=${page.faq_default}`" class="btn-default">
          <span>{{ $t('More specific FAQ') }}</span>
        </router-link>
      </div>
    </Faq>
    <div class="scroll-top">
      <ScrollToTop/>
    </div>
  </SiteMain>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import SiteMain from "@/components/SiteMain.vue";
import PageLayout from "@/components/PageLayout.vue";
import GridSection from "@/components/GridSection.vue";
import Card from "@/components/Card.vue";
import ScrollToTop from "@/components/ScrollToTop.vue";
import Faq from "@/components/Faq.vue";

export default {
  components: {
    Faq,
    VueSlickCarousel,
    SiteMain,
    PageLayout,
    GridSection,
    Card,
    ScrollToTop,
  },
  data() {
    return {
      previousPage: "/home",
      carouselSettings: {
        arrows: false,
        dots: false,
        slidesToShow: 3,
        slidesToScroll: 0,
        infinite: true,
        draggable: false,
        swipe: false,
        responsive: [
          {
            breakpoint: 575.98,
            settings: {
              slidesToShow: 1.5,
              slidesToScroll: 1,
              draggable: true,
              swipe: true,
              dots: true,
            },
          },
        ],
      },
    };
  },
  computed: {
    page() {
      return this.$store.state.cache.data[`phases_${this.$route.params.slug}_${this.$i18n.locale}`]?.phase ?? {};
    },
    posts() {
      return this.$store.state.cache.data[`phases_${this.$route.params.slug}_${this.$i18n.locale}`]?.contents ?? [];
    },
    faqTitle() {
      return `FAQ ${this.$t('Phase')} ${this.page?.title ?? ''}`;
    }
  },
  methods: {
    async loadPage() {
      const key = `phases_${this.$route.params.slug}_${this.$i18n.locale}`;
      const url = `${process.env.VUE_APP_API_URL}/api/phases/get?locale=${this.$i18n.locale}&slug=` + this.$route.params.slug;
      this.$store.commit('cacheRequest', {key, url});
    },
  },
  created() {
    this.loadPage();
  },
  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.loadPage();
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  color: white;
  padding: 80px 0;
  border-top: 1px solid #303030;
  @include tablet-down {
    padding: 48px 0;
  }

  .slick-slider {
    width: 100%;

    /deep/ .slick-dots {
      display: flex !important;
      justify-content: center;
      align-items: flex-end;
      padding-top: 24px;

      li {
        width: 15px;
        display: inline;
        margin-right: 5px;
      }

      button {
        background: #0078d6;
        opacity: 0.32;
        border: none !important;
        font-size: 0;
        height: 10px;
        width: 10px;
        border-radius: 50px;
      }
    }

    /deep/ .slick-dots li.slick-active button {
      opacity: 1 !important;
    }
  }
}

.faq-cta--btn {
  padding: 32px 0;
  text-align: center;
  font-size: 14px;

  p {
    color: #a4aaae;
    margin-bottom: 16px;
  }
}
</style>
